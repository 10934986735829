<template>
  <fieldset>
    <!--TODO find a way to hide Title when no field is used-->
    <h4 class="level3">
      {{ $t("editTrip.effortStep.deploymentInfos") }}
    </h4>
    <fieldset>
      <IntegerInput
        v-if="hasEffortDetailNbLines"
        :label="$t('editTrip.effortStep.nbLines')"
        v-model="tow['linesCount']"
        :min="1"
        :max="99"
        :required="hasEffortDetailNbLines === $const.MANDATORY"
        @error="addError"
        :refeshError="towRefreshError"
      />

      <IntegerInput
        v-if="hasEffortDetailNbLines"
        :label="$t('editTrip.effortStep.nbStrikes')"
        v-model="tow['strikesCount']"
        :min="1"
        :max="999"
        :required="hasEffortDetailNbLines === $const.MANDATORY"
        @error="addError"
        :refeshError="towRefreshError"
      />

      <IntegerInput
        v-if="hasEffortDetailNbBuoys"
        :label="$t('editTrip.effortStep.nbBuoys')"
        v-model="tow['buoysCount']"
        :required="hasEffortDetailNbBuoys === $const.MANDATORY"
        @error="addError"
        :refeshError="towRefreshError"
      />

      <IntegerInput
        v-if="hasEffortDetailNbHooks"
        :label="$t('editTrip.effortStep.nbHooks')"
        v-model="tow['hooksCount']"
        :min="1"
        :max="999999"
        :required="hasEffortDetailNbHooks === $const.MANDATORY"
        @error="addError"
        :refeshError="towRefreshError"
      />

      <Select
        v-if="hasEffortDetailBaitType"
        :label="$t('editTrip.effortStep.baitType')"
        :options="systemsLists.baitType"
        v-model="tow['baitType']"
        :required="hasEffortDetailBaitType === $const.MANDATORY"
      />

      <Select
        v-if="hasBaitCategory"
        :label="$t('editTrip.effortStep.baitCategory')"
        :options="systemsLists.baitCategory"
        v-model="tow['baitCategory']"
        :required="hasBaitCategory === $const.MANDATORY"
      />

      <UnitConverter
        v-if="hasWaterSurfaceTemperature"
        :label="$t('editTrip.effortStep.waterSurfaceTemp')"
        v-model="tow['waterSurfaceTemp']"
        :required="hasWaterSurfaceTemperature === $const.MANDATORY"
        :min="-5"
        :max="40"
        unit="temperature"
        @error="addError"
        :refresh="towRefreshError"
      />

      <UnitConverter
        v-if="hasAirTemperature"
        :label="$t('editTrip.effortStep.airTemp')"
        v-model="tow['airTemp']"
        :min="-70"
        :max="40"
        unit="temperature"
        :required="hasAirTemperature === $const.MANDATORY"
        @error="addError"
        :refresh="towRefreshError"
      />

      <UnitConverter
        v-if="hasAverageBottomDepth"
        :label="$t('editTrip.effortStep.averageBottomDepth')"
        v-model="tow['averageBottomDepth']"
        :required="hasAverageBottomDepth === $const.MANDATORY"
        :min="1"
        :max="9999"
        unit="depth"
        @error="addError"
        :refresh="towRefreshError"
      />

      <RadioInput
        v-if="hasEffortDetailSpotterPlane"
        v-model="tow['spotterPlane']"
        :label="$t('editTrip.effortStep.hasSpotterPlane')"
        :options="systemsLists.response"
        :refeshError="towRefreshError"
        :required="hasEffortDetailSpotterPlane === $const.MANDATORY"
        @error="addError"
      />

      <Select
        v-if="hasEffortDetailPacStatArea"
        :label="$t('editTrip.effortStep.statSecteur')"
        :options="pacAreas"
        v-model="tow['secteur']"
        :required="hasEffortDetailPacStatArea === $const.MANDATORY"
        @error="addError"
        :refeshError="towRefreshError"
      />

      <SoakDuration
        v-if="hasSoakDuration"
        :label="$t('editTrip.effortStep.soakDuration')"
        v-model="tow['soakDuration']"
        :required="hasSoakDuration === $const.MANDATORY"
        :soakDurationMax="maxSoakValue"
        :useDays="true"
        @error="addError"
        :refeshError="towRefreshError"
      />
      <IntegerInput
        v-if="hasEffortDetailNbGearsHauled"
        :label="$t('editTrip.effortStep.nbGearHauled')"
        v-model="tow['nbGearHauled']"
        :required="hasEffortDetailNbGearsHauled === $const.MANDATORY"
        :min="1"
        :forceErrorMsg="tooManyGearsMessage"
        @error="addError"
        :refeshError="towRefreshError"
      />
      <IntegerInput
        v-if="hasEffortDetailNbGearsLost"
        :label="$t('editTrip.effortStep.nbGearLost')"
        v-model="tow['nbGearLost']"
        :required="hasEffortDetailNbGearsLost === $const.MANDATORY"
        :min="0"
        :forceErrorMsg="tooManyGearsMessage"
        @error="addError"
        :refeshError="towRefreshError"
      />

      <UnitConverter
        v-if="hasMinMaxGearDepth"
        :label="$t('editTrip.effortStep.minGearDepth')"
        v-model="tow['minimumGearDepth']"
        :required="hasMinMaxGearDepth === $const.MANDATORY"
        :min="0"
        :max="9999"
        unit="depth"
        @error="addError"
        :refresh="towRefreshError"
      />
      <UnitConverter
        v-if="hasMinMaxGearDepth"
        :label="$t('editTrip.effortStep.maxGearDepth')"
        v-model="tow['maximumGearDepth']"
        :required="hasMinMaxGearDepth === $const.MANDATORY"
        :min="0"
        :max="9999"
        unit="depth"
        @error="addError"
        :refresh="towRefreshError"
      />
      <UnitConverter
        v-if="hasAverageGearDepth"
        :label="$t('editTrip.effortStep.averageGearDepth')"
        v-model="tow['averageGearDepth']"
        :required="hasAverageGearDepth === $const.MANDATORY"
        :min="1"
        :max="9999"
        unit="depth"
        @error="addError"
        :refresh="towRefreshError"
      />
      <DecimalInput
        v-if="hasAverageTowSpeed"
        :label="$t('editTrip.effortStep.averageTowSpeed')"
        v-model="tow['averageTowSpeed']"
        :required="hasAverageTowSpeed === $const.MANDATORY"
        :max="9.9"
        :maxDecimal="1"
        @error="addError"
        :refeshError="towRefreshError"
      />
      <UnitConverter
        v-if="hasDist"
        :label="$t('editTrip.effortStep.distanceTravelled')"
        v-model="tow['distance']"
        :required="hasDist === $const.MANDATORY"
        :max="99999"
        :min="0"
        unit="m"
        @error="addError"
        :refresh="towRefreshError"
      />
      <IntegerInput
        v-if="hasRmpCnt1"
        :label="$t('editTrip.effortStep.firstCalculatedWeigth')"
        v-model="tow['firstCount']"
        :min="1"
        :max="9999"
        :required="hasRmpCnt1 === $const.MANDATORY"
        @error="addError"
        :refeshError="towRefreshError"
      />
      <RadioInput
        v-if="hasBaitProtected"
        v-model="tow['protectedBait']"
        :label="$t('fields.hasBaitProtected')"
        :min="0"
        :options="systemsLists.response"
        :refeshError="towRefreshError"
        :required="hasBaitProtected === $const.MANDATORY"
        @error="addError"
      />
      <Select
        v-if="hasFishMethAndBtCatgId"
        :label="$t('editTrip.effortStep.fishingMethod')"
        v-model="tow['fishingMethod']"
        :required="hasFishMethAndBtCatgId === $const.MANDATORY"
        :options="fishingMethodOptions"
        @error="addError"
        :refeshError="towRefreshError"
      />
      <UnitConverter
        v-if="hasTotGearLen"
        :label="$t('editTrip.effortStep.totalGearLength')"
        v-model="tow['totalGearLength']"
        :required="hasTotGearLen === $const.MANDATORY"
        :min="1"
        :max="99999"
        unit="m"
        @error="addError"
        :refresh="towRefreshError"
      />
      <UnitConverter
        v-if="hasEffortByGearRiggedLength"
        :label="$t('editTrip.effortStep.riggedPartLength')"
        v-model="tow['riggedPartLength']"
        :required="hasEffortByGearRiggedLength === $const.MANDATORY"
        :min="1"
        :max="99999"
        unit="m"
        @error="addError"
        :refresh="towRefreshError"
      />
      <IntegerInput
        v-if="hasEffortByGearRiggedLength"
        :label="$t('editTrip.effortStep.skatesCount')"
        v-model="tow['skatesCount']"
        :required="hasEffortByGearRiggedLength === $const.MANDATORY"
        :min="1"
        :max="999"
        @error="addError"
        :refeshError="towRefreshError"
      />
      <GenericInput
        v-if="hasFishSiteDsc"
        :label="$t('editTrip.effortStep.fishSite')"
        v-model="tow['fishSite']"
        type="text"
        :maxlength="100"
        :required="hasFishSiteDsc === $const.MANDATORY"
        @error="addError"
        :refeshError="towRefreshError"
      />
      <UnitConverter
        v-if="hasEffortDetailTargetAndGearDepth"
        :label="$t('editTrip.effortStep.targetDepth')"
        v-model="tow['targetDepth']"
        :required="hasEffortDetailTargetAndGearDepth === $const.MANDATORY"
        :min="0"
        :max="9999"
        unit="depth"
        @error="addError"
        :refresh="towRefreshError"
      />
      <UnitConverter
        v-if="hasEffortDetailSpread"
        :label="$t('editTrip.effortStep.warpLength')"
        v-model="tow['warpLength']"
        :required="hasEffortDetailSpread === $const.MANDATORY"
        :min="1"
        :max="999"
        unit="m"
        @error="addError"
        :refresh="towRefreshError"
      />
      <UnitConverter
        v-if="hasEffortDetailSpread"
        :label="$t('editTrip.effortStep.wingSpread')"
        v-model="tow['wingSpread']"
        :required="hasEffortDetailSpread === $const.MANDATORY"
        :min="1"
        :max="999"
        unit="m"
        @error="addError"
        :refresh="towRefreshError"
      />
      <UnitConverter
        v-if="hasEffortDetailSpread"
        :label="$t('editTrip.effortStep.doorSpread')"
        v-model="tow['doorSpread']"
        :required="hasEffortDetailSpread === $const.MANDATORY"
        :min="1"
        :max="999"
        unit="m"
        @error="addError"
        :refresh="towRefreshError"
      />
      <UnitConverter
        v-if="hasEffortDetailCatchDepth"
        :label="$t('editTrip.effortStep.optimalDepth')"
        v-model="tow['optimalDepth']"
        :required="hasEffortDetailCatchDepth === $const.MANDATORY"
        :min="0"
        :max="9999"
        unit="depth"
        @error="addError"
        :refresh="towRefreshError"
      />
      <UnitConverter
        v-if="hasEffortDetailMeshSize"
        :label="$t('fields.meshSize')"
        v-model="tow['meshSize']"
        :required="hasEffortDetailMeshSize === $const.MANDATORY"
        :min="1"
        :max="999"
        unit="mm"
        @error="addError"
        :refresh="towRefreshError"
      />
      <UnitConverter
        v-if="hasFishGearDepth"
        :label="$t('editTrip.effortStep.startGearDepth')"
        v-model="tow['startGearDepth']"
        :required="hasFishGearDepth === $const.MANDATORY"
        :min="0"
        :max="9999"
        unit="depth"
        @error="addError"
        :refresh="towRefreshError"
      />
      <UnitConverter
        v-if="hasFishGearDepth"
        :label="$t('editTrip.effortStep.endGearDepth')"
        v-model="tow['endGearDepth']"
        :required="hasFishGearDepth === $const.MANDATORY"
        :min="0"
        :max="9999"
        unit="depth"
        @error="addError"
        :refresh="towRefreshError"
      />
      <Select
        v-if="hasEffortDetailHooks"
        :label="$t('editTrip.effortStep.hookType')"
        v-model="tow['hookType']"
        :required="hasEffortDetailHooks === $const.MANDATORY"
        :options="systemsLists.hookType"
        @error="addError"
        :refeshError="towRefreshError"
      />
      <Select
        v-if="hasEffortDetailHooks"
        :label="$t('editTrip.effortStep.hookSize')"
        v-model="tow['hookSize']"
        :required="hasEffortDetailHooks === $const.MANDATORY"
        :options="systemsLists.hookSize"
        @error="addError"
        :refeshError="towRefreshError"
      />
      <UnitConverter
        v-if="hasEffortDetailDeployment"
        :label="$t('editTrip.effortStep.averageGearDistance')"
        v-model="tow['averageGearDistance']"
        :required="hasEffortDetailDeployment === $const.MANDATORY"
        :min="1"
        :max="99999"
        unit="m"
        @error="addError"
        :refresh="towRefreshError"
      />
      <UnitConverter
        v-if="hasEffortDetailSpread"
        :label="$t('editTrip.effortStep.middleGearDepth')"
        v-model="tow['middleGearDepth']"
        :required="hasEffortDetailSpread === $const.MANDATORY"
        :min="0"
        :max="9999"
        unit="depth"
        @error="addError"
        :refresh="towRefreshError"
      />
      <Select
        v-if="hasEffortDetailTargetAndGearDepth"
        :label="$t('editTrip.effortStep.gearDepthCategory')"
        v-model="tow['gearDepthCategory']"
        :required="hasEffortDetailTargetAndGearDepth === $const.MANDATORY"
        :options="systemsLists.waterDepthCategories"
        @error="addError"
        :refeshError="towRefreshError"
      />
      <UnitConverter
        v-if="hasEffortDetailSpread"
        :label="$t('editTrip.effortStep.verticalSpread')"
        v-model="tow['verticalSpread']"
        :required="hasEffortDetailSpread === $const.MANDATORY"
        :min="1"
        :max="999"
        unit="m"
        @error="addError"
        :refresh="towRefreshError"
      />
      <IntegerInput
        v-if="hasNbHooksLost"
        :label="$t('editTrip.effortStep.hookLostCount')"
        v-model="tow['hookLostCount']"
        :required="hasNbHooksLost === $const.MANDATORY"
        :min="0"
        :max="999999"
        @error="addError"
        :refeshError="towRefreshError"
      />
      <IntegerInput
        v-if="hasEstWtFullSite"
        :label="$t('editTrip.effortStep.potentialSiteWeight')"
        v-model="tow['potentialSiteWeight']"
        :required="hasEstWtFullSite === $const.MANDATORY"
        :min="0"
        :max="9999"
        @error="addError"
        :refeshError="towRefreshError"
      />
      <GenericInput
        v-if="hasRsnSetrel"
        :label="$t('editTrip.effortStep.releaseCause')"
        v-model="tow['releaseCause']"
        type="text"
        :maxlength="100"
        :required="hasRsnSetrel === $const.MANDATORY"
        @error="addError"
        :refeshError="towRefreshError"
      />
      <Select
        v-if="hasWthrCondId"
        :label="$t('editTrip.effortStep.weatherConditions')"
        v-model="tow['weatherConditions']"
        :required="hasWthrCondId === $const.MANDATORY"
        :options="systemsLists.weatherConditions"
        @error="addError"
        :refeshError="towRefreshError"
      />
      <Select
        v-if="hasFishDensId"
        :label="$t('editTrip.effortStep.populationDensity')"
        v-model="tow['populationDensity']"
        :required="hasFishDensId === $const.MANDATORY"
        :options="systemsLists.populationDensity"
        @error="addError"
        :refeshError="towRefreshError"
      />
      <Select
        v-if="hasFishDensId"
        :label="$t('editTrip.effortStep.speciesSize')"
        v-model="tow['speciesSize']"
        :required="hasFishDensId === $const.MANDATORY"
        :options="fishSizeOptions"
        @error="addError"
        :refeshError="towRefreshError"
      />
      <RadioInput
        v-if="hasEffortDetailSpawnIndicator"
        v-model="tow['spawningActivity']"
        :label="$t('editTrip.effortStep.spawningActivity')"
        :options="systemsLists.response"
        :refeshError="towRefreshError"
        :required="hasEffortDetailSpawnIndicator === $const.MANDATORY"
        @error="addError"
      />
      <IntegerInput
        v-if="hasNbGearHldCatch"
        :label="$t('editTrip.effortStep.nbGearWithCatch')"
        v-model="tow['nbGearWithCatch']"
        :required="hasNbGearHldCatch === $const.MANDATORY"
        :min="0"
        :max="9999"
        @error="addError"
        :refeshError="towRefreshError"
      />
      <IntegerInput
        v-if="hasEffortDetailNbTows"
        :label="$t('editTrip.effortStep.towCount')"
        v-model="tow['towCount']"
        :required="hasEffortDetailNbTows === $const.MANDATORY"
        :min="1"
        :max="999"
        @error="addError"
        :refeshError="towRefreshError"
      />
      <GenericInput
        v-if="hasEffortDetailSetName"
        :label="$t('editTrip.effortStep.setNumber')"
        v-model="tow['setNumber']"
        type="text"
        :maxlength="30"
        :required="hasEffortDetailSetName === $const.MANDATORY"
        @error="addError"
        :refeshError="towRefreshError"
      />
      <IntegerInput
        v-if="hasEffortDetailSetCount"
        :label="$t('editTrip.effortStep.setsCount')"
        v-model="tow['setsCount']"
        :required="hasEffortDetailSetCount === $const.MANDATORY"
        :min="1"
        :max="999"
        @error="addError"
        :refeshError="towRefreshError"
      />
      <br />
      <DateInput
        v-if="hasEffortDetailSonar"
        :label="$t('editTrip.effortStep.sonarStart')"
        v-model="tow['sonarStart']"
        :required="hasEffortDetailSonar === $const.MANDATORY"
        :minDates="[
          {
            value: currentTrip.departure['datetime'],
            text: $t('editTrip.beforeDepartureDate')
          }
        ]"
        :passDate="true"
        :autodatetime="true"
        @error="addError"
        :refeshError="towRefreshError"
      />
      <DateInput
        v-if="hasEffortDetailSonar"
        :label="$t('editTrip.effortStep.sonarEnd')"
        v-model="tow['sonarEnd']"
        :required="hasEffortDetailSonar === $const.MANDATORY"
        :minDates="[
          {
            value: tow['sonarStart'],
            text: $t('editTrip.effortStep.beforeSonarStart')
          },
          {
            value: currentTrip.departure['datetime'],
            text: $t('editTrip.beforeDepartureDate')
          }
        ]"
        :passDate="true"
        :autodatetime="true"
        @error="addError"
        :refeshError="towRefreshError"
      />
      <br />
      <br />
      <b-col v-if="hasEffortDetailMidPosition">
        <span>
          <label> {{ $t("editTrip.effortStep.midPosition") }} </label>
        </span>
      </b-col>
      <GeopositionWidget
        v-if="hasEffortDetailMidPosition"
        :position="tow['midPosition']"
        @binding="
          value => {
            tow['midPosition'] = value;
            update();
          }
        "
        :minLat="[area.latMin ?? geoLimits.minLat]"
        :maxLat="[area.latMax ?? geoLimits.maxLat]"
        :minLong="[area.lonMin ?? geoLimits.minLon]"
        :maxLong="[area.lonMax ?? geoLimits.maxLon]"
        :area="effort.area"
        :dissableSSSections="true"
        :required="hasEffortDetailMidPosition === $const.MANDATORY"
        @error="addError"
        :refeshError="towRefreshError"
      />
    </fieldset>
  </fieldset>
</template>

<script>
import IntegerInput from "@/components/subformEditTrip/widgets/IntegerInput.vue";
import SoakDuration from "@/components/subformEditTrip/widgets/SoakDuration.vue";
import GeopositionWidget from "@/components/widgets/GeopositionWidget.vue";
import DecimalInput from "@/components/subformEditTrip/widgets/DecimalInput.vue";
import Select from "@/components/Select.vue";
import GenericInput from "@/components/GenericInput.vue";
import { mapGetters, mapState } from "vuex";
import UnitConverter from "@/components/widgets/UnitConverter.vue";
import RadioInput from "@/components/widgets/RadioInput.vue";
import DateInput from "@/components/widgets/DateInput.vue";

export default {
  name: "Info",
  components: {
    GenericInput,
    IntegerInput,
    GeopositionWidget,
    SoakDuration,
    DecimalInput,
    Select,
    RadioInput,
    DateInput,
    UnitConverter
  },
  props: {
    addError: Function,
    towRefreshError: Number, // serve as trigger to tell the fields to add an error if the value is invalid
    tow: Object,
    effortIndex: Number,
    effort: Object
  },
  computed: {
    ...mapGetters(["getPropertyValue", "buildOptions"]),
    ...mapState({
      currentTrip: state => state.selectedTrip,
      systemsLists: state => state.systemsLists,
      effortAreas: state => state.editTripSubform.effortAreas,
      geoLimits: state => state.currentSubscription.module.geoLimits,
      form: state => state.currentOpenTrip.form,
      subform: state => state.currentOpenTrip.subform
    }),
    soakDurationMaxs() {
      return this.getPropertyValue(
        "maxSoakDuration",
        { subforms: this.subform },
        99999
      );
    },
    fishingMethods() {
      return this.getPropertyValue(
        "fishingMethods",
        { subforms: this.subform },
        []
      );
    },
    hasAverageTowSpeed() {
      return this.getPropertyValue("hasAverageTowSpeed", {
        subforms: this.subform
      });
    },
    hasRmpCnt1() {
      return this.getPropertyValue("hasRmpCnt1", {
        subforms: this.subform
      });
    },
    hasDist() {
      return this.getPropertyValue("hasDist", {
        subforms: this.subform
      });
    },
    hasEffortDetailNbLines() {
      return this.getPropertyValue("hasEffortDetailNbLines", {
        subforms: this.subform
      });
    },
    hasEffortDetailNbBuoys() {
      return this.getPropertyValue("hasEffortDetailNbBuoys", {
        subforms: this.subform
      });
    },
    hasEffortDetailSpotterPlane() {
      return this.getPropertyValue("hasEffortDetailSpotterPlane", {
        subforms: this.subform
      });
    },
    hasEffortDetailBaitType() {
      return this.getPropertyValue("hasEffortDetailBaitType", {
        subforms: this.subform
      });
    },
    hasFishMethAndBtCatgId() {
      return this.getPropertyValue("hasFishMethAndBtCatgId", {
        subforms: this.subform
      });
    },
    hasWaterSurfaceTemperature() {
      return this.getPropertyValue("hasWaterSurfaceTemperature", {
        subforms: this.subform
      });
    },
    hasAirTemperature() {
      return this.getPropertyValue("hasAirTemperature", {
        subforms: this.subform
      });
    },
    hasAverageBottomDepth() {
      return this.getPropertyValue("hasAverageBottomDepth", {
        subforms: this.subform
      });
    },
    hasBaitCategory() {
      return this.getPropertyValue("hasBaitCategory", {
        subforms: this.subform
      });
    },
    hasFishSiteDsc() {
      return this.getPropertyValue("hasFishSiteDsc", {
        subforms: this.subform
      });
    },
    hasEffortDetailTargetAndGearDepth() {
      return this.getPropertyValue("hasEffortDetailTargetAndGearDepth", {
        subforms: this.subform
      });
    },
    hasEffortDetailSpread() {
      return this.getPropertyValue("hasEffortDetailSpread", {
        subforms: this.subform
      });
    },
    hasEffortDetailCatchDepth() {
      return this.getPropertyValue("hasEffortDetailCatchDepth", {
        subforms: this.subform
      });
    },
    hasFishGearDepth() {
      return this.getPropertyValue("hasFishGearDepth", {
        subforms: this.subform
      });
    },
    hasNbHooksLost() {
      return this.getPropertyValue("hasNbHooksLost", {
        subforms: this.subform
      });
    },
    hasEffortDetailMidPosition() {
      return this.getPropertyValue("hasEffortDetailMidPosition", {
        subforms: this.subform
      });
    },
    hasEffortDetailPacStatArea() {
      return this.getPropertyValue("hasEffortDetailPacStatArea", {
        subforms: this.subform
      });
    },
    hasWthrCondId() {
      return this.getPropertyValue("hasWthrCondId", {
        subforms: this.subform
      });
    },
    fishingMethodOptions() {
      return this.buildOptions(
        this.fishingMethods,
        this.systemsLists.fishingMethod,
        this.hasFishMethAndBtCatgId === this.$const.MANDATORY
      );
    },
    fishSizeId() {
      return this.getPropertyValue(
        "fishSizeId",
        { subforms: this.subform },
        []
      );
    },
    fishSizeOptions() {
      return this.buildOptions(
        this.fishSizeId,
        this.systemsLists.productSize,
        this.hasFishDensId === this.$const.MANDATORY
      );
    },
    hasEffortDetailSpawnIndicator() {
      return this.getPropertyValue("hasEffortDetailSpawnIndicator", {
        subforms: this.subform
      });
    },
    hasNbGearHldCatch() {
      return this.getPropertyValue("hasNbGearHldCatch", {
        subforms: this.subform
      });
    },
    hasFishDensId() {
      return this.getPropertyValue("hasFishDensId", {
        subforms: this.subform
      });
    },
    hasEstWtFullSite() {
      return this.getPropertyValue("hasEstWtFullSite", {
        subforms: this.subform
      });
    },
    hasRsnSetrel() {
      return this.getPropertyValue("hasRsnSetrel", {
        subforms: this.subform
      });
    },
    hasEffortDetailNbTows() {
      return this.getPropertyValue("hasEffortDetailNbTows", {
        subforms: this.subform
      });
    },
    hasEffortDetailSetName() {
      return this.getPropertyValue("hasEffortDetailSetName", {
        subforms: this.subform
      });
    },
    hasEffortDetailSetCount() {
      return this.getPropertyValue("hasEffortDetailSetCount", {
        subforms: this.subform
      });
    },
    hasEffortDetailSonar() {
      return this.getPropertyValue("hasEffortDetailSonar", {
        subforms: this.subform
      });
    },
    area() {
      return this.effortAreas[this.effortIndex] ?? {};
    },
    hasBaitProtected() {
      return this.getPropertyValue("hasBaitProtected", {
        subforms: this.subform,
        gears: this.effort["gears"]
      });
    },
    hasSoakDuration() {
      return this.getPropertyValue("hasSoakDuration", {
        forms: this.form,
        subforms: this.subform,
        gears: this.effort.gears
      });
    },
    hasEffortDetailNbHooks() {
      return this.getPropertyValue("hasEffortDetailNbHooks", {
        subforms: this.subform,
        gears: this.effort.gears
      });
    },
    hasEffortDetailDeployment() {
      return this.getPropertyValue("hasEffortDetailDeployment", {
        subforms: this.subform,
        gears: this.effort.gears
      });
    },
    hasEffortDetailHooks() {
      return this.getPropertyValue("hasEffortDetailHooks", {
        subforms: this.subform,
        gears: this.effort.gears
      });
    },
    hasEffortByGearRiggedLength() {
      return this.getPropertyValue("hasEffortByGearRiggedLength", {
        subforms: this.subform,
        gears: this.effort.gears
      });
    },
    hasTotGearLen() {
      return this.getPropertyValue("hasTotGearLen", {
        subforms: this.subform,
        gears: this.effort.gears
      });
    },
    hasEffortDetailMeshSize() {
      return this.getPropertyValue("hasEffortDetailMeshSize", {
        subforms: this.subform,
        gears: this.effort.gears
      });
    },
    hasEffortDetailNbGearsHauled() {
      return this.getPropertyValue("hasEffortDetailNbGearsHauled", {
        subforms: this.subform,
        gears: this.effort.gears
      });
    },
    hasEffortDetailNbGearsLost() {
      return this.getPropertyValue("hasEffortDetailNbGearsLost", {
        subforms: this.subform
      });
    },
    hasMinMaxGearDepth() {
      return this.getPropertyValue("hasMinMaxGearDepth", {
        subforms: this.subform,
        gears: this.effort["gears"]
      });
    },
    hasAverageGearDepth() {
      return this.getPropertyValue("hasAverageGearDepth", {
        subforms: this.subform,
        gears: this.effort["gears"]
      });
    },
    isDays() {
      return this.soakDurationMax / 24 >= 1;
    },
    maxSoakValue() {
      return this.soakDurationMax * 60;
    },
    pacAreas() {
      return this.getPropertyValue(
        "pacAreas",
        {
          forms: this.form,
          subforms: this.subform,
          areas: this.effort.area
        },
        this.systemsLists.pareas
      );
    },
    tooManyGearsMessage() {
      let total = 0;
      const effort = this.effort;
      let licenceTotal = this.effort["nbGearMax"];
      for (let j in effort.tows) {
        const tow = effort.tows[j];
        total += parseInt(tow["nbGearHauled"]);
      }
      if (total > parseInt(licenceTotal)) {
        return this.$t("editTrip.effortStep.errorNbGearMax");
      }
      return undefined;
    }
  },
  watch: {
    hasSoakDuration(newValue) {
      if (!newValue) {
        delete this.tow["soakDuration"];
      }
    },
    hasBaitProtected(newValue) {
      if (!newValue) {
        delete this.effort.baitProtected;
      }
    },
    pacAreas(newPacAreas) {
      if (newPacAreas.length === 1) {
        this.tow.secteur = newPacAreas[0].value;
      }
    }
  },
  methods: {
    update() {
      this.$emit("update");
    }
  }
};
</script>
