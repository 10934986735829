<template>
  <div>
    <h3 class="step-title">
      <span>
        <span>
          <span>{{ $t("editTrip.experimentalStep.title") }}</span>
        </span>
      </span>
    </h3>

    <div
      class="experimentalSectionsContainer"
      id="experimentalSectionsContainer"
    >
      <div
        v-for="(sectionId, index) in this.subscription.experimentalSections"
        :key="index"
      >
        <div :class="'section section-' + index">
          <h4 class="level1" style="margin-bottom: 15px !important;">
            {{ sectionId.title }}
          </h4>
          <div v-for="(group, index2) in sectionId.groups" :key="index2">
            <div :class="'group group-' + index2 + ' nb-gear-' + gearNumber">
              <div v-for="(gear, index3) in group.gears" :key="index3">
                <div
                  :class="
                    'gear gear-' +
                      index3 +
                      ' nb-column-' +
                      allowedCatchTypesNumber
                  "
                >
                  <h5>
                    {{ gear.type.description }}
                  </h5>
                  <div
                    :class="'row column-' + allowedCatchTypesNumber + ' title'"
                  >
                    <div
                      v-for="(catchTypeId, index4) in gear.allowedCatchTypes"
                      :key="index4"
                    >
                      <h6>
                        {{ catchTypeId.abbrv }}
                      </h6>
                    </div>
                  </div>
                  <div
                    v-for="(numberForExGear,
                    index5) in NbRowForAnExperimentalGear[index3]"
                    :key="index5"
                  >
                    <div :class="'row nb-column-' + allowedCatchTypesNumber">
                      <div
                        v-for="(catchTypeId, index6) in gear.allowedCatchTypes"
                        :key="index6"
                      >
                        <Experimentalinput
                          :id="
                            'experimentalCatch-' +
                              index3 +
                              '-' +
                              index6 +
                              '-' +
                              (index5 + 1)
                          "
                          v-model="
                            form.experimentalData[index3][index5 + 1][index6]
                          "
                          :min="0"
                          :max="catchTypeId.value_max"
                          :digits="true"
                          @error="addErrors"
                          :refeshError="showErrorData"
                          :errorMsg="'MAX ' + catchTypeId.value_max"
                          type="text"
                          @input="handleInput(index3, index5)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p class="clearBother"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Experimentalinput from "@/components/ExperimentalInput.vue";
import { mapState, mapActions } from "vuex";

const name = "experimentalStep";
export default {
  components: {
    Experimentalinput
  },
  created() {},
  props: ["value", "checkError", "refeshError"],
  data: () => ({
    experimentalCatchArray: [],
    NbRowForAnExperimentalGear: [],
    allowedCatchTypesNumber: 0,
    showErrorData: 0,
    gearNumber: 0,
    form: {
      experimentalData: {}
    }
  }),
  computed: {
    ...mapState({
      openTrip: state => state.currentOpenTrip,
      currentPage: state => state.editTripSubform.currentPage,
      isFinalReview: state => state.editTripSubform.isFinalReview,
      subscription: state => state.currentSubscription,
      showError: state => state.editTripSubform.showError
    })
  },
  watch: {
    showError() {
      if (this.isFinalReview || this.currentPage === name) {
        ++this.showErrorData;
      }
    },
    form: {
      deep: true,
      handler: function() {
        this.$emit("update-form", this.form);
      }
    }
  },
  methods: {
    ...mapActions(["addErrorsToState"]),
    addErrors(errors) {
      this.addErrorsToState({ page: name, errors: errors });
    },
    adjustExperimentalValues() {
      var needDelete = true;
      var newRowIndex;
      var exDataTmp = {};
      for (var gearId in this.form.experimentalData) {
        exDataTmp[gearId] = {};
        newRowIndex = 1;
        for (var rowId in this.form.experimentalData[gearId]) {
          exDataTmp[gearId][rowId] = {};
          for (var catchTypeId in this.form.experimentalData[gearId][rowId]) {
            if (
              this.form.experimentalData[gearId][rowId][catchTypeId] != null
            ) {
              needDelete = false;
            }
          }
          if (!needDelete) {
            for (var tmpCatchId in this.form.experimentalData[gearId][rowId]) {
              exDataTmp[gearId][newRowIndex][
                tmpCatchId
              ] = this.form.experimentalData[gearId][rowId][tmpCatchId];
            }
            newRowIndex++;
          }
          needDelete = true;
        }
        for (var catchTypeId in this.form.experimentalData[gearId][rowId]) {
          exDataTmp[gearId][newRowIndex][catchTypeId] = null;
        }
      }
      this.openTrip.experimentalData = exDataTmp;
      this.updateForm();
    },
    handleInput(gearIdNumber, rowNumber) {
      var tmpObj = {};
      var newRowNumber = rowNumber + 2;
      if (this.NbRowForAnExperimentalGear[gearIdNumber] == rowNumber + 1) {
        this.NbRowForAnExperimentalGear[gearIdNumber]++;
        this.showErrorData++;
        this.form.experimentalData[gearIdNumber][newRowNumber] = {};
        for (
          var typeIndex = 1;
          typeIndex <= this.allowedCatchTypesNumber;
          typeIndex++
        ) {
          tmpObj[typeIndex] = null;
        }
        this.form.experimentalData[gearIdNumber][newRowNumber] = tmpObj;
      }
    },
    fillValues() {
      var newGearIdndex;
      this.adjustExperimentalValues();
      for (var gearId in this.form.experimentalData) {
        newGearIdndex = 0;
        for (var catchColum in this.form.experimentalData[gearId]) {
          var newCatchIdIndex = 0;
          for (var catchExperimentalAllowed in this.form.experimentalData[
            gearId
          ][catchColum]) {
            newCatchIdIndex++;
          }
          if (newCatchIdIndex > 0) newGearIdndex++;
        }
        if (this.NbRowForAnExperimentalGear[gearId] < newGearIdndex)
          this.NbRowForAnExperimentalGear[gearId] = newGearIdndex;
      }
    },
    initValues() {
      var sections = this.subscription.experimentalSections;
      var len = 0;
      for (var sectionId in sections) {
        var section = sections[sectionId];
        for (var groupId in section.groups) {
          var group = section.groups[groupId];
          this.gearNumber = 0;
          for (var gearId in group.gears) {
            len = 0;
            for (var tmp in this.form.experimentalData[gearId]) {
              len++;
            }
            if (len === 0) {
              this.form.experimentalData[gearId] = {};
            }
            var gear = group.gears[gearId];
            this.gearNumber = this.gearNumber + 1;
            this.allowedCatchTypesNumber = 0;
            for (var tmp in gear.allowedCatchTypes) {
              this.allowedCatchTypesNumber = this.allowedCatchTypesNumber + 1;
            }
            if (
              this.NbRowForAnExperimentalGear[gearId] == null ||
              this.NbRowForAnExperimentalGear[gearId] == 0
            ) {
              this.NbRowForAnExperimentalGear[
                gearId
              ] = this.getNbRowForAnExperimentalGear(gear);
              for (
                var rowIndex = 1;
                rowIndex <= this.getNbRowForAnExperimentalGear(gear);
                rowIndex++
              ) {
                len = 0;
                for (var tmp in this.form.experimentalData[gearId][rowIndex]) {
                  len++;
                }
                if (len === 0) {
                  this.form.experimentalData[gearId][rowIndex] = {};
                  for (
                    var typeIndex = 1;
                    typeIndex <= this.allowedCatchTypesNumber;
                    typeIndex++
                  ) {
                    this.form.experimentalData[gearId][rowIndex][
                      typeIndex
                    ] = null;
                  }
                }
              }
            }
          }
        }
      }
    },
    getNbRowForAnExperimentalGear(gear) {
      var NB_ROW_DEFAULT = 4;
      var gearId = gear.id;
      var nbRow = 0;
      var nbRowForEachColumn = {};
      var catchTypeId;
      for (catchTypeId in gear.allowedCatchTypes) {
        nbRowForEachColumn[catchTypeId] = 0;
      }
      for (var i in this.openTrip) {
        if (i.indexOf("experimentalCatch") != -1) {
          if (parseInt(i.split("-")[1]) == gearId) {
            catchTypeId = parseInt(i.split("-")[2]);
            nbRowForEachColumn[catchTypeId] =
              nbRowForEachColumn[catchTypeId] + 1;
          }
        }
      }
      for (catchTypeId in nbRowForEachColumn) {
        if (nbRow < nbRowForEachColumn[catchTypeId]) {
          nbRow = nbRowForEachColumn[catchTypeId];
        }
      }
      if (nbRow < NB_ROW_DEFAULT) {
        return NB_ROW_DEFAULT;
      }
      return nbRow;
    },
    updateForm() {
      if (this.openTrip && "creation" in this.openTrip) {
        this.form.experimentalData = this.openTrip.experimentalData;
      }
    }
  },
  mounted() {
    this.updateForm();
    this.initValues();
    this.fillValues();
  }
};
</script>
<style lang="scss"></style>
