import i18n from "@/i18n.js";
import Geolocation from "@/utils/geolocation.js";
import { formatTimeIntervalInMinutes } from "@/utils/utils.js";

// For use by parseDate()
let hideTime = false;

export default {
  genericValidation(value, field) {
    // The field argument is required; otherwise we don't know how to test the value ;-)
    if (!field) {
      return { error: false };
    }

    // Issue a warning when the type of field is unknown
    if (
      ![
        "datalist",
        "datetime",
        "duration",
        "email",
        "gps_lat",
        "gps_long",
        "gridValidation",
        "nafoValidation",
        "nafoGridValidation",
        "number",
        "phone",
        "select",
        "tags",
        "text"
      ].includes(field.type)
    ) {
      console.info("Field type '%s' is unknown; assuming 'text'", field.type); // eslint-disable-line no-console
      field.type = "text";
    }

    // First, we'll take care of the simple cases when no value is provided
    if (noValueProvided(value)) {
      // Case 1: the field is required
      if (field.required) {
        return { error: true, msg: i18n.t("required") };
      }

      // Case 2: the field is part of a pair of fields where at least one must be provided
      // If the other field is not provided either, then it is an error.
      if (field.bothFieldRequired && noValueProvided(field.notBothFields)) {
        return {
          error: true,
          msg: i18n.t("validator.atLeastOneValueRequired")
        };
      }

      // Otherwise, there is no error.
      return { error: false };
    }

    // If the value contains tags, then value is an array that is limited to a maximum number of entries.
    if (field.type === "tags") {
      if (!Array.isArray(value)) {
        console.warn("value must be an array, currently:", typeof value); // eslint-disable-line no-console
      }

      const max = field.max || 0;
      if (value.length > max) {
        return {
          error: true,
          msg: i18n.t("validator.toomanytags")
        };
      }
    }

    if (field.oneFieldAtMax) {
      return {
        error: true,
        notBlocked: true,
        msg: i18n.t("validator.atMostOneValueRequired")
      };
    }

    if (field.maxDecimal >= 0) {
      let valueAsString = "" + Math.abs(value).toString();
      let integerSection = "" + Math.trunc(value * 1);
      let decimalLength = valueAsString.length - 1 - integerSection.length;
      if (decimalLength > field.maxDecimal) {
        if (field.maxDecimal) {
          return {
            error: true,
            msg: i18n.t("validator.maxDecimal", { max: field.maxDecimal })
          };
        } else {
          return {
            error: true,
            msg: i18n.t("validator.noDecimal")
          };
        }
      }
    }

    // Value must be a number
    if (field.digits) {
      // Remove the decimal point and verify that the string contains only digits
      let checkValue = value.toString().replace(".", "");
      if (checkValue.toString().match(/^[0-9]+$/) === null) {
        return { error: true, msg: i18n.t("validator.enterValidNumber") };
      }

      // The number may have a minimum value
      if (field.min !== undefined && value < field.min) {
        return {
          error: true,
          msg: i18n.t("validator.lessThan", { min: field.min })
        };
      }

      // The number may have a maximum value
      if (field.max !== undefined && value > field.max) {
        return {
          error: true,
          msg: i18n.t("validator.greaterThan", { max: field.max })
        };
      }
    }

    // A duration is possibly limited to a maximum value
    if (field.type === "duration") {
      if (field.max) {
        // The minimum is only checked when a maximum exist.
        if (value < 1) {
          return {
            error: true,
            msg: i18n.t("validator.lessThan", { min: 1 })
          };
        }

        if (value > field.max) {
          let time = formatTimeIntervalInMinutes(field.max);
          return {
            error: true,
            msg: i18n.t("validator.greaterThan", { max: time })
          };
        }
      }
    }

    if (
      field.digits ||
      field.type === "number" ||
      field.type === "text" ||
      field.type === "datalist"
    ) {
      if (field.finValidator) {
        return finValidator(value);
      }
      if (field.maxlength && value.toString().length > field.maxlength) {
        return {
          error: true,
          msg: i18n.tc("validator.maxLength", field.maxlength)
        };
      }
      if (field.minlength && value.toString().length < field.minlength) {
        return {
          error: true,
          msg: i18n.tc("validator.minLength", field.minlength)
        };
      }
    }

    if (field.type === "datetime") {
      // Used by parseDate())
      hideTime = field.hideTime || false;

      if (field.minDates) {
        for (let date of field.minDates) {
          if (date.value && parseDate(value) < parseDate(date.value)) {
            return {
              error: true,
              msg: date.text
            };
          }
        }
      }

      if (field.maxDates) {
        for (let date of field.maxDates) {
          if (date.value && parseDate(value) > parseDate(date.value)) {
            return {
              error: true,
              msg: date.text
            };
          }
        }
      }

      if (field.passDate) {
        const now = new Date();
        if (parseDate(value) > now) {
          return {
            error: true,
            msg: i18n.t("validator.earlierThanNow")
          };
        }
      }
    }

    if (field.type === "email" && value.indexOf("@") === -1) {
      return {
        error: true,
        msg: i18n.t("validator.invalidEmail")
      };
    }

    if (field.type === "phone") {
      let number = value.replace(/\D/g, "").replace(1, "");
      if (field.phoneUS) {
        if (number.length !== 10) {
          return {
            error: true,
            msg: i18n.t("validator.invalidPhone")
          };
        }
      }
    }

    if (field.type === "number") {
      if (field.min || field.min === 0) {
        if (value < field.min) {
          return {
            error: true,
            msg: i18n.t("validator.lessThan", { min: field.min })
          };
        }
      }
      if (field.max || field.max === 0) {
        if (value > field.max) {
          return {
            error: true,
            msg: i18n.t("validator.greaterThan", { max: field.max })
          };
        }
      }
    }

    if (field.type === "select") {
      // The array validator contains values that are not allowed
      if (field.arrayValidator) {
        for (let item of field.arrayValidator) {
          if (value == item) {
            return { error: true, msg: i18n.t("required") };
          }
        }
      }
    }

    if (field.type === "gps_lat") {
      if (Number.isNaN(value)) {
        return { error: true, msg: i18n.t("validator.invalidLat") };
      }

      let min = Number.POSITIVE_INFINITY;
      let max = Number.NEGATIVE_INFINITY;

      if (field.minLat) {
        for (let lat of field.minLat) {
          if (min > Number(lat)) {
            min = Number(lat);
          }
        }
      }

      if (field.maxLat) {
        for (let lat of field.maxLat) {
          if (max < Number(lat)) {
            max = Number(lat);
          }
        }
      }

      if (min !== Number.POSITIVE_INFINITY) {
        if (Number(value) < min) {
          return {
            error: true,
            msg: i18n.t("validator.outsideFishingArea", {
              min: Geolocation.convertDD2toDMS(min, "lat").toString,
              max: Geolocation.convertDD2toDMS(max, "lat").toString
            })
          };
        }
      }

      if (max !== Number.NEGATIVE_INFINITY) {
        if (Number(value) > max) {
          return {
            error: true,
            msg: i18n.t("validator.outsideFishingArea", {
              min: Geolocation.convertDD2toDMS(min, "lat").toString,
              max: Geolocation.convertDD2toDMS(max, "lat").toString
            })
          };
        }
      }
    }

    if (field.type === "gps_long") {
      if (Number.isNaN(value)) {
        return { error: true, msg: i18n.t("validator.invalidLon") };
      }

      let min = Number.POSITIVE_INFINITY;
      let max = Number.NEGATIVE_INFINITY;

      if (field.minLong) {
        for (let lon of field.minLong) {
          if (min > Number(lon)) {
            min = Number(lon);
          }
        }
      }

      if (field.maxLong) {
        for (let lon of field.maxLong) {
          if (max < Number(lon)) {
            max = Number(lon);
          }
        }
      }

      if (min !== Number.POSITIVE_INFINITY) {
        if (Number(value) < min) {
          return {
            error: true,
            msg: i18n.t("validator.outsideFishingArea", {
              min: Geolocation.convertDD2toDMS(min, "lon").toString,
              max: Geolocation.convertDD2toDMS(max, "lon").toString
            })
          };
        }
      }

      if (max !== Number.NEGATIVE_INFINITY) {
        if (Number(value) > max) {
          return {
            error: true,
            msg: i18n.t("validator.outsideFishingArea", {
              min: Geolocation.convertDD2toDMS(min, "lon").toString,
              max: Geolocation.convertDD2toDMS(max, "lon").toString
            })
          };
        }
      }
    }
    if (
      field.type === "gridValidation" &&
      field.gridValidationArray &&
      field.gridValidationArray.length > 0
    ) {
      if (!field.gridValidationArray.includes(value)) {
        return {
          error: true,
          msg: i18n.t("validator.gridOutsideFishingArea")
        };
      }
    }

    if (
      field.type === "nafoValidation" &&
      field.nafoGridValidationArray &&
      Object.keys(field.nafoGridValidationArray).length
    ) {
      if (!field.nafoGridValidationArray[value]) {
        return {
          error: true,
          msg: i18n.t("validator.nafoOutsideFishingArea")
        };
      }
    }

    if (
      field.type === "nafoGridValidation" &&
      field.nafoGridValidationArray &&
      Object.keys(field.nafoGridValidationArray).length
    ) {
      if (field.nafoGridValidationArray[field.nafoPosition]) {
        if (
          !field.nafoGridValidationArray[field.nafoPosition].includes(value)
        ) {
          return {
            error: true,
            msg: i18n.t("validator.gridOutsideFishingArea")
          };
        }
      }
    }

    return { error: false };
  }
};

// Returns true if no value is provided
function noValueProvided(value) {
  return (
    value === undefined || // value is undefined
    value === null || // value is null
    value === "" || // value is an empty string
    (Array.isArray(value) && value.length === 0) // value is an empty array
  );
}

// Checks the FIN's format against rule 260 of FS-NAT-233-1
//
// These patterns are supported:
//   1) 5, 6 or 9 digits
//   2) 1 digit + letter C or D + 7 digits
//   3) DFOCC + 1 to 9 digits
//   4) 6ASU + 1 to 5 digits
//
// Returns false if no error detected;
// otherwise returns true and an error message.
function finValidator(fin) {
  const pattern = /^\d{5,6}$|^\d[C-D0-9]\d{7}$|^DFOCC\d{1,9}$|^6ASU\d{1,5}$/;
  return pattern.test(fin)
    ? { error: false }
    : { error: true, msg: i18n.t("fin.error") };
}

// Reformats a date string into a Date object with or without its time portion
function parseDate(string) {
  if (hideTime) {
    string = string.split("T")[0];
  }
  return new Date(string);
}
