<template>
  <fieldset
    :disabled="effort['closeDatetime'] !== undefined"
    name="BeginEffort"
  >
    <h4 class="level2">
      {{ $t("editTrip.effortStep.beginningOfEffort") }}
      <span
        v-b-modal="'modal-Trip-' + index + '-startEffort'"
        v-show="effort['closeDatetime'] === undefined"
        class="add-remark-btn-title"
      />
      <Popup
        v-model="effort['remark']"
        :title-label="$t('editTrip.remarkTitle')"
        :maxlength="2000"
        :modalName="'modal-Trip-' + index + '-startEffort'"
      />
    </h4>
    <DateInput
      v-model="effort.start"
      :label="
        isNewEffort
          ? $t('editTrip.summary.datetime')
          : $t('editTrip.effortStep.start')
      "
      :required="true"
      :passDate="true"
      :autodatetime="true"
      :minDates="[
        {
          value: openTrip.departure['datetime'],
          text: $t('editTrip.beforeDepartureDate')
        }
      ]"
      :forceErrorMsg="lastTripDateAndEffort(effort.start, index)"
      :refeshError="showErrorData"
      @error="addErrorToParent"
    />
    <GeopositionWidget
      v-if="hasPositionInEffort > 0 && hasEffortDetailStartPosition > 0"
      :position="effort.startPosition"
      :hasNafo="hasGeopositionNafo"
      :hasGrid="hasGeopositionGrid"
      :hasLobsterGrid="hasLobsterGrid"
      :area="effort.area"
      :required="true"
      :minLat="[area.latMin ?? geoLimits.minLat]"
      :maxLat="[area.latMax ?? geoLimits.maxLat]"
      :minLong="[area.lonMin ?? geoLimits.minLon]"
      :maxLong="[area.lonMax ?? geoLimits.maxLon]"
      :gridValidationArray="gridValidationArray"
      :nafoGridValidationArray="nafoGridValidationArray"
      :refeshError="showErrorData"
      :lgrid="effort.lgrid"
      @binding="
        value => {
          effort.startPosition = value;
        }
      "
      @error="addErrorToParent"
    />
    <Select
      v-if="hasPfmaId"
      v-model="effort.parea"
      :label="$t('editTrip.effortStep.parea')"
      :required="hasPfmaId === $const.MANDATORY"
      :options="pacAreaOptions"
      :refeshError="showErrorData"
      @error="addErrorToParent"
    />
    <IntegerInput
      v-if="hasEffortNbSets"
      v-model="effort.setsCount"
      :label="$t('editTrip.effortStep.setsCount')"
      :min="1"
      :max="999"
      :required="hasEffortNbSets === $const.MANDATORY"
      :refeshError="showErrorData"
      @error="addErrorToParent"
    />
    <GenericInput
      v-if="hasLicNo2"
      v-model="effort.licenceNumber2"
      :label="$t('editTrip.effortStep.licenceNumber2')"
      :minlength="4"
      :maxlength="$const.licenceMaxLength"
      :forceErrorMsg="uniqueLicenceNumber(effort.licenceNumber2)"
      :required="hasLicNo2 === $const.MANDATORY"
      :refeshError="showErrorData"
      type="text"
      @error="addErrorToParent"
    />
    <br />
    <DateInput
      v-if="hasEffortSonar"
      v-model="effort.sonarStart"
      :label="$t('editTrip.effortStep.sonarStart')"
      :required="hasEffortSonar === $const.MANDATORY"
      :passDate="true"
      :autodatetime="true"
      :minDates="[
        {
          value: effort.start,
          text: $t('editTrip.beforeStartDate')
        },
        {
          value: openTrip.departure['datetime'],
          text: $t('editTrip.beforeDepartureDate')
        }
      ]"
      :refeshError="showErrorData"
      @error="addErrorToParent"
    />
    <Select
      v-if="hasWaterbodyId"
      v-model="effort.waterbody"
      :label="$t('editTrip.effortStep.waterbody')"
      :required="hasWaterbodyId === $const.MANDATORY"
      :options="waterbodies"
      :refeshError="showErrorData"
      @error="addErrorToParent"
    />
    <Select
      v-if="hasLicSbtypId"
      v-model="effort.licenceSubtype"
      :label="$t('editTrip.effortStep.licenceSubtype')"
      :required="hasLicSbtypId === $const.MANDATORY"
      :options="licenceSubtypeOptions"
      :refeshError="showErrorData"
      @error="addErrorToParent"
    />
  </fieldset>
</template>
<script>
import GenericInput from "@/components/GenericInput.vue";
import GeopositionWidget from "@/components/widgets/GeopositionWidget.vue";
import IntegerInput from "@/components/subformEditTrip/widgets/IntegerInput.vue";
import Select from "@/components/Select.vue";

import { mapGetters, mapState } from "vuex";
import Popup from "@/components/widgets/Popup.vue";
import DateInput from "@/components/widgets/DateInput.vue";

export default {
  name: "BeginEffort",
  components: {
    GenericInput,
    GeopositionWidget,
    Select,
    Popup,
    DateInput,
    IntegerInput
  },
  props: {
    addErrorToParent: Function,
    showErrorData: Number, // serve as trigger to tell the fields to add an error if the value is invalid
    effort: Object,
    licence: Object,
    form: Object,
    index: Number,
    isNewEffort: Boolean
  },
  computed: {
    ...mapGetters(["getPropertyValue", "buildOptions"]),
    ...mapState({
      openTrip: state => state.currentOpenTrip,
      formId: state => state.currentOpenTrip.form,
      subform: state => state.currentOpenTrip.subform,
      MvLicenceSubtypes: state => state.systemsLists.licenceSubtype,
      tripList: state => state.currentTripList,
      closedTrips: state => state.closedTrips,
      effortAreas: state => state.editTripSubform.effortAreas,
      geoLimits: state => state.currentSubscription.module.geoLimits,
      gridValidationArray: state => state.editTripSubform.gridValidationArray,
      nafoGridValidationArray: state =>
        state.editTripSubform.nafoGridValidationArray
    }),
    hasGeopositionNafo() {
      return this.getPropertyValue("hasGeopositionNafo", {
        subforms: this.subform
      });
    },
    hasPfmaId() {
      return this.getPropertyValue("hasPfmaId", {
        subforms: this.subform
      });
    },
    hasEffortNbSets() {
      return this.getPropertyValue("hasEffortNbSets", {
        subforms: this.subform
      });
    },
    hasLicNo2() {
      return this.getPropertyValue("hasLicNo2", {
        subforms: this.subform
      });
    },
    hasEffortDetailStartPosition() {
      return this.getPropertyValue("hasEffortDetailStartPosition", {
        subforms: this.subform
      });
    },
    hasEffortSonar() {
      return this.getPropertyValue("hasEffortSonar", {
        subforms: this.subform
      });
    },
    hasWaterbodyId() {
      return this.getPropertyValue("hasWaterbodyId", {
        subforms: this.subform
      });
    },
    waterbodies() {
      return this.getPropertyValue("waterbodies", {
        subforms: this.subform
      });
    },
    hasLicSbtypId() {
      return this.getPropertyValue("hasLicSbtypId", {
        subforms: this.subform
      });
    },
    licenceSubtypes() {
      return this.getPropertyValue(
        "licenceSubtypes",
        { subforms: this.subform },
        []
      );
    },
    licenceSubtypeOptions() {
      return this.buildOptions(
        this.licenceSubtypes,
        this.MvLicenceSubtypes,
        this.hasLicSbtypId === this.$const.MANDATORY
      );
    },
    pacAreas() {
      return this.getPropertyValue(
        "pacAreas",
        {
          form: this.formId,
          subforms: this.subform,
          areas: this.effort.area
        },
        []
      );
    },
    pacAreaOptions() {
      return [
        {
          value: null,
          text: "---------",
          disabled: this.hasPfmaId === this.$const.MANDATORY
        },
        ...this.pacAreas
      ];
    },
    area() {
      return this.effortAreas[this.index] ?? {};
    },
    hasPositionInEffort() {
      return this.getPropertyValue("hasPositionInEffort", {
        forms: this.formId
      });
    },
    hasStatSection() {
      return this.getPropertyValue("hasStatSection", {
        subforms: this.subform,
        areas: this.effort.area
      });
    },
    hasGeopositionGrid() {
      return this.getPropertyValue("hasGeopositionGrid", {
        subforms: this.subform,
        targetSpecies: this.effort.target,
        areas: this.effort.area
      });
    },
    hasLobsterGrid() {
      return this.getPropertyValue("hasLobsterGrid", {
        subforms: this.subform,
        areas: this.effort.area
      });
    }
  },
  methods: {
    uniqueLicenceNumber(licenceNumber) {
      if (!this.licence) {
        return undefined;
      }
      if (this.licence.fishingLicenceNumber === licenceNumber) {
        return this.$t("fields.licenceError");
      }
      return undefined;
    },
    lastTripDateAndEffort(date, effortIndex) {
      for (let i in this.form.efforts) {
        const effort = this.form.efforts[i];
        const value = new Date(date);
        if (effortIndex.toString() !== i.toString()) {
          if (
            effort.start &&
            value >= new Date(effort.start) &&
            effort.end &&
            value <= new Date(effort.end)
          ) {
            return this.$t("editTrip.rangeOtherEffort");
          }
        }
      }
      return this.lastTripDate(date);
    },
    lastTripDate(date) {
      let rv;
      for (let i in this.tripList) {
        const trip = this.tripList[i];
        rv = this.checkOverlapDate(trip, date);
        if (rv) {
          return rv;
        }
      }
      for (let i in this.closedTrips) {
        const trip = this.closedTrips[i];
        rv = this.checkOverlapDate(trip, date);
        if (rv) {
          return rv;
        }
      }

      return rv;
    },
    checkOverlapDate(trip, date) {
      const currentDate = new Date(date);
      const departure = new Date(trip.departure.datetime);
      let arrival;
      if (trip.arrival && trip.arrival.datetime) {
        arrival = new Date(trip.arrival.datetime);
      } else if (
        trip.arrival.cargoEvents &&
        trip.arrival.cargoEvents[0] &&
        trip.arrival.cargoEvents[0].datetime
      ) {
        arrival = new Date(trip.arrival.cargoEvents[0].datetime);
      }
      if (currentDate >= departure && currentDate <= arrival) {
        return this.$t("editTrip.rangeOtherTrip");
      }

      if (this.openTrip.departure?.datetime != null) {
        const formDeparture = new Date(this.openTrip.departure.datetime);
        if (departure > formDeparture && departure < currentDate) {
          return this.$t("editTrip.rangeOtherTrip");
        }
        if (arrival > formDeparture && arrival < currentDate) {
          return this.$t("editTrip.rangeOtherTrip");
        }
      }
      return undefined;
    }
  }
};
</script>
