<template>
  <div>
    <div v-for="(tow, index2) in effort.tows" :key="index2">
      <div>
        <fieldset class="tow">
          <h4 class="level2 sticky">
            {{ $t("editTrip.effortStep.tow") }}&nbsp;{{ index2 + 1 }}
            <span
              v-b-modal="'modal-Trip-' + index + '-' + index2 + '-tow'"
              v-show="effort['closeDatetime'] === undefined"
              class="add-remark-btn-title"
            ></span>
          </h4>
          <Popup
            v-model="tow['remark']"
            :title-label="$t('editTrip.remarkTitle')"
            :maxlength="2000"
            :modalName="'modal-Trip-' + index + '-' + index2 + '-tow'"
          />
          <SoakDuration
            v-if="soakDurationMax > 0"
            :label="$t('editTrip.effortStep.soakDuration')"
            v-model="tow['soakDuration']"
            :required="true"
            :soakDurationMax="maxSoakValue"
            :useDays="true"
            @error="addError"
            :refeshError="towRefreshError"
          />
          <IntegerInput
            v-if="hasEffortDetailNbGearsHauled"
            :label="$t('editTrip.effortStep.nbGearHauled')"
            v-model="tow['nbGearHauled']"
            :required="hasEffortDetailNbGearsHauled === $const.MANDATORY"
            :min="1"
            :forceErrorMsg="TotalNumberOfGears"
            @error="addError"
            :refeshError="towRefreshError"
          />

          <IntegerInput
            v-if="hasEffortDetailNbGearsLost"
            :label="$t('editTrip.effortStep.nbGearLost')"
            v-model="tow['nbGearLost']"
            :required="hasEffortDetailNbGearsLost === $const.MANDATORY"
            :min="0"
            :forceErrorMsg="TotalNumberOfGears"
            @error="addError"
            :refeshError="towRefreshError"
          />

          <CatchManager
            :effortIndex="index"
            :towIndex="index2"
            :effort="effort"
            :showModal="effort['closeDatetime'] === undefined"
            :showErrorData="towRefreshError"
            :addError="addError"
            :tow="tow"
            @update-catches="data => updateCatches(index2, data)"
            @update-bycatches="data => updateBycatches(index2, data)"
          />

          <BaitUsed
            v-if="hasBaitTowNum"
            :baitUsages="tow.baitUsages"
            :addError="addError"
            :showErrorData="towRefreshError"
          />

          <Special
            :addErrorToParent="addError"
            :tow="tow"
            :SPECIMEN_MAX="SPECIMEN_MAX"
            :showErrorData="towRefreshError"
            :area="effort.area"
          />
        </fieldset>
      </div>
    </div>
    <fieldset class="suppEntryButtonWrapper">
      <p>
        <input
          v-if="effort.tows.length > 1"
          @click="removeTow()"
          class="removeSuppEntryButton"
          type="button"
          :value="
            $t('editTrip.effortStep.removeTow') + String(effort.tows.length)
          "
        />
        <input
          @click="addTow()"
          class="addSuppEntryButton"
          type="button"
          :value="$t('editTrip.effortStep.addTow')"
        />
      </p>
    </fieldset>
  </div>
</template>
<script>
import CatchManager from "@/components/subformEditTrip/EffortStepComponents/Tow/CatchManager.vue";
import Special from "@/components/subformEditTrip/EffortStepComponents/Tow/Special.vue";
import BaitUsed from "@/components/subformEditTrip/EffortStepComponents/BaitUsed.vue";

import Tow from "@/models/Tow.js";
//import Select from "@/components/Select.vue";
import IntegerInput from "@/components/subformEditTrip/widgets/IntegerInput.vue";
import SoakDuration from "@/components/subformEditTrip/widgets/SoakDuration.vue";

import { mapState, mapActions, mapGetters } from "vuex";
import { keysMatchingValue, scrollToTopField } from "@/utils/utils";

import Vue from "vue";
import Popup from "@/components/widgets/Popup.vue";

export default {
  components: {
    //Select,
    IntegerInput,
    SoakDuration,
    CatchManager,
    BaitUsed,
    Popup,
    Special
  },
  props: {
    showErrorData: Number, // serve as trigger to tell the fields to add an error if the value is invalid
    effort: Object,
    form: Object,
    index: Number,
    addErrorToParent: Function
  },
  data() {
    return {
      SPECIMEN_MAX: 9999,
      towRefreshError: 0
    };
  },
  computed: {
    ...mapGetters(["getPropertyValue"]),
    ...mapState({
      stateErrors: state => state.editTripSubform.errors,
      subform: state => state.editTripSubform.subform
    }),
    soakDurationMax() {
      return this.getPropertyValue(
        "maxSoakDuration",
        {
          subforms: this.subform
        },
        99999
      );
    },
    // TODO: Is this prop really usefull here ?  It seems to me that the BaitUsed component is the one that is targeted by this prop.
    // The presence or absence of the BaitUsed page is solely controlled by hasBaitUsed.
    hasBaitTowNum() {
      return this.getPropertyValue("hasBaitTowNum", {
        subforms: this.subform
      });
    },
    isDays() {
      return this.soakDurationMax / 24 >= 1;
    },
    maxSoakValue() {
      return this.soakDurationMax * 60;
    },
    hasEffortDetailNbGearsHauled() {
      return this.getPropertyValue("hasEffortDetailNbGearsHauled", {
        subforms: this.subform,
        gears: this.effort.gears
      });
    },
    hasEffortDetailNbGearsLost() {
      return this.getPropertyValue("hasEffortDetailNbGearsLost", {
        subforms: this.subform
      });
    },
    TotalNumberOfGears() {
      let total = 0;
      let licenceTotal = this.effort["nbGearMax"];
      for (let j in this.effort.tows) {
        const tow = this.effort.tows[j];
        total += parseInt(tow["nbGearHauled"]);
      }

      if (total > parseInt(licenceTotal)) {
        return this.$t("editTrip.effortStep.errorNbGearMax");
      }
      return undefined;
    }
  },
  watch: {
    showErrorData() {
      this.towRefreshError++;
    }
  },
  methods: {
    ...mapActions(["addErrorsToState"]),
    addError(errors) {
      this.addErrorsToState({ page: "tows-" + this.index, errors: errors });
      this.addErrorToParent(errors);
    },
    updateCatches(index, catches) {
      this.effort.tows[index].catches = catches;
    },
    updateBycatches(index, bycatches) {
      this.effort.tows[index].bycatches = bycatches;
    },
    addTow() {
      const vm = this;
      let soakDuration = null;

      vm.towRefreshError++;
      vm.$nextTick(() => {
        const errorsFieldsNames = keysMatchingValue(
          this.stateErrors,
          true,
          "tows-" + this.index
        );
        if (errorsFieldsNames.length > 0) {
          scrollToTopField(errorsFieldsNames);
          return;
        }

        if (
          this.form.efforts.length === 1 &&
          this.effort.tows.length === 0 &&
          this.soakDurationMax > 1
        ) {
          soakDuration = 1440;
        }

        let copyData = { ...vm.form.efforts[this.index] };
        let newTow = new Tow();
        newTow.init({ soakDuration: soakDuration });
        copyData.tows.push(newTow);

        Vue.set(vm.form.efforts, this.index, copyData);
      });
    },
    removeTow() {
      let copyData = [...this.form.efforts[this.index].tows];
      copyData.pop();
      Vue.set(this.form.efforts[this.index], "tows", copyData);
    }
  },
  mounted() {
    if (!this.effort.tows || this.effort.tows.length === 0) {
      this.addTow();
    }
  }
};
</script>
