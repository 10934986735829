<template>
  <div name="TowViewer">
    <div
      v-for="(tow, towIndex) in tows"
      :key="'tow-' + towIndex"
      :name="'Tow ' + towIndex"
    >
      <fieldset class="fieldsetViewTrip">
        <h4 class="level2 sticky viewStep">
          {{ $t("editTrip.effortStep.tow") }}&nbsp;{{ towIndex + 1 }}
        </h4>
        <div v-if="tow.startPosition || tow.startDate">
          <h4 class="level3">
            {{
              isTowTextMode
                ? $t("editTrip.effortStep.startTow")
                : $t("editTrip.effortStep.startDeployment")
            }}
          </h4>
          <b-row v-if="tow.startDate">
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.start')"
                :value="$showDate(tow.startDate)"
                type="text"
              />
            </b-col>
            <b-col v-if="tow['startArea']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('fields.area')"
                :value="tow['startArea']"
                :options="areas"
                type="select"
              />
            </b-col>
          </b-row>
          <!-- TODO: The b-row should test for the presence of startposition. -->
          <b-row v-if="tow.startPosition && tow.startPosition['latitude']">
            <!-- TODO: The b-col should test for the presence of latitude. -->
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('latitude')"
                :value="
                  convertDD2toDMS(tow.startPosition['latitude'], 'lat') +
                    ' = ' +
                    tow.startPosition['latitude'] +
                    '°'
                "
                type="text"
              />
            </b-col>
            <!-- TODO: The b-col should test for the presence of longitude. -->
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('longitude')"
                :value="
                  convertDD2toDMS(tow.startPosition['longitude'], 'lon') +
                    ' = ' +
                    tow.startPosition['longitude'] +
                    '°'
                "
                type="text"
              />
            </b-col>
            <b-col v-if="tow.startPosition['nafo']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('nafo')"
                :value="tow.startPosition['nafo']"
                type="text"
              />
            </b-col>
            <b-col v-if="tow.startPosition['grid']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('grid')"
                :value="tow.startPosition['grid']"
                type="text"
              />
            </b-col>
            <b-col v-if="tow.startPosition.lgrid" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('lgrid')"
                :value="tow.startPosition.lgrid"
                :options="systemsLists.lGrids"
                type="text"
              />
            </b-col>
          </b-row>
        </div>
        <div v-if="tow['startDate'] && isOldModule">
          <h4>
            {{ $t("editTrip.effortStep.startDeployment") }}
          </h4>
          <b-row>
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.start')"
                :value="$showDate(tow['startDate'])"
                type="text"
              />
            </b-col>
          </b-row>
          <b-row v-if="tow['startPosition']['latitude']">
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('latitude')"
                :value="
                  convertDD2toDMS(tow['startPosition']['latitude'], 'lat') +
                    ' = ' +
                    tow['startPosition']['latitude'] +
                    '°'
                "
                type="text"
              />
            </b-col>
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('longitude')"
                :value="
                  convertDD2toDMS(tow['startPosition']['longitude'], 'lon') +
                    ' = ' +
                    tow['startPosition']['longitude'] +
                    '°'
                "
                type="text"
              />
            </b-col>
          </b-row>
        </div>
        <div>
          <InfoViewer :tow="tow" :area="area" />
        </div>
        <h4 v-if="tow.catches && tow.catches.length > 0" class="level3">
          {{ $t("editTrip.effortStep.catches") }}
        </h4>
        <CatchViewer
          v-for="(capture, catchIndex) in tow.catches"
          :key="'capture-' + catchIndex"
          :capture="capture"
        />
        <h4 v-if="tow.bycatches && tow.bycatches.length > 0" class="level3">
          {{ $t("editTrip.effortStep.bycatches") }}
        </h4>
        <CatchViewer
          v-for="(capture, bycatchIndex) in tow.bycatches"
          :key="'bycatch-' + bycatchIndex"
          :capture="capture"
        />
        <TagsViewer
          v-if="tow.tags && tow.tags.length > 0"
          :tags="tow.tags"
          :headerLevel="3"
        />
        <div v-if="tow['farGearPosition']">
          <h4 class="level3">
            {{ $t("editTrip.effortStep.farGear") }}
          </h4>
          <b-row v-if="tow['farGearPosition']">
            <b-col
              v-if="tow['farGearPosition']['latitude']"
              lg="4"
              sm="6"
              cols="12"
            >
              <LabelView
                :label="$t('latitude')"
                :value="
                  convertDD2toDMS(tow['farGearPosition']['latitude'], 'lat') +
                    ' = ' +
                    tow['farGearPosition']['latitude'] +
                    '°'
                "
                type="text"
              />
            </b-col>
            <b-col
              v-if="tow['farGearPosition']['longitude']"
              lg="4"
              sm="6"
              cols="12"
            >
              <LabelView
                :label="$t('longitude')"
                :value="
                  convertDD2toDMS(tow['farGearPosition']['longitude'], 'lon') +
                    ' = ' +
                    tow['farGearPosition']['longitude'] +
                    '°'
                "
                type="text"
              />
            </b-col>
          </b-row>
        </div>

        <div v-if="tow['endPosition'] || tow['endDate']" name="endTow">
          <h4 class="level3">
            {{
              isTowTextMode
                ? $t("editTrip.effortStep.endTow")
                : $t("editTrip.effortStep.endDeployment")
            }}
          </h4>
          <b-row>
            <b-col v-if="tow['endDate']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.end')"
                :value="$showDate(tow['endDate'])"
                type="text"
              />
            </b-col>
            <b-col lg="4" sm="6" cols="12" v-if="tow['endArea']">
              <LabelView
                :label="$t('fields.area')"
                :value="tow['endArea']"
                :options="areas"
                type="select"
              />
            </b-col>
          </b-row>
          <b-row v-if="tow['endPosition']">
            <b-col
              v-if="tow['endPosition']['latitude']"
              lg="4"
              sm="6"
              cols="12"
            >
              <LabelView
                :label="$t('latitude')"
                :value="
                  convertDD2toDMS(tow['endPosition']['latitude'], 'lat') +
                    ' = ' +
                    tow['endPosition']['latitude'] +
                    '°'
                "
                type="text"
              />
            </b-col>
            <b-col
              v-if="tow['endPosition']['longitude']"
              lg="4"
              sm="6"
              cols="12"
            >
              <LabelView
                :label="$t('longitude')"
                :value="
                  convertDD2toDMS(tow['endPosition']['longitude'], 'lon') +
                    ' = ' +
                    tow['endPosition']['longitude'] +
                    '°'
                "
                type="text"
              />
            </b-col>
            <b-col v-if="tow.endPosition['nafo']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('nafo')"
                :value="tow.endPosition['nafo']"
                type="text"
              />
            </b-col>
            <b-col v-if="tow.endPosition['grid']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('grid')"
                :value="tow.endPosition['grid']"
                type="text"
              />
            </b-col>
            <b-col v-if="tow.endPosition.lgrid" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('lgrid')"
                :value="tow.endPosition.lgrid"
                :options="systemsLists.lGrids"
                type="text"
              />
            </b-col>
          </b-row>
        </div>
        <h4
          v-if="
            tow['nbVnotchObserved'] ||
              tow['nbSelfVnotched'] ||
              tow['nbBigLobster']
          "
          class="level3"
        >
          {{ $t("editTrip.effortStep.special") }}
        </h4>
        <fieldset
          v-if="
            tow['nbVnotchObserved'] ||
              tow['nbSelfVnotched'] ||
              tow['nbBigLobster']
          "
          name="nbVnotchObserved"
        >
          <b-row>
            <b-col v-if="tow['nbVnotchObserved']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.nbVnotchObserved')"
                :value="tow['nbVnotchObserved']"
                type="text"
              />
            </b-col>
            <b-col v-if="tow['nbSelfVnotched']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.nbSelfVnotched')"
                :value="tow['nbSelfVnotched']"
                type="text"
              />
            </b-col>
            <b-col v-if="tow['nbBigLobster']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.effortStep.nbBigLobster')"
                :value="tow['nbBigLobster']"
                type="text"
              />
            </b-col>
          </b-row>
        </fieldset>
        <h4
          v-if="
            tow.gearLossEvents && tow.gearLossEvents.length > 0 && !isOldModule
          "
        >
          {{ $t("editTrip.gearLossStep.title") }}
        </h4>
        <div v-if="!isOldModule">
          <fieldset
            v-for="(gearLoss, gearlossIndex) in tow.gearLossEvents"
            :key="'gearloss-' + gearlossIndex"
          >
            <h4 v-if="tow.gearLossEvents.length > 1">
              {{ $t("editTrip.gearLossStep.loss") + " " + (gearlossIndex + 1) }}
            </h4>
            <b-row>
              <b-col
                v-if="gearLoss.occursAt['datetime']"
                lg="4"
                sm="6"
                cols="12"
              >
                <LabelView
                  :label="$t('editTrip.summary.datetime')"
                  :value="$showDate(gearLoss.occursAt['datetime'])"
                  type="text"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-if="gearLoss.occursAt['latitude']"
                lg="4"
                sm="6"
                cols="12"
              >
                <LabelView
                  :label="$t('latitude')"
                  :value="
                    convertDD2toDMS(gearLoss.occursAt['latitude'], 'lat') +
                      ' = ' +
                      gearLoss.occursAt['latitude'] +
                      '°'
                  "
                  type="text"
                />
              </b-col>
              <b-col
                v-if="gearLoss.occursAt['longitude']"
                lg="4"
                sm="6"
                cols="12"
              >
                <LabelView
                  :label="$t('longitude')"
                  :value="
                    convertDD2toDMS(gearLoss.occursAt['longitude'], 'lon') +
                      ' = ' +
                      gearLoss.occursAt['longitude'] +
                      '°'
                  "
                  type="text"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.gearLossStep.gearType')"
                  :value="gearLoss['gearType']"
                  :options="systemsLists.gearType"
                  type="select"
                />
              </b-col>
              <b-col lg="4" sm="6" cols="12" v-if="gearLoss['nbGear']">
                <LabelView
                  :label="$t('editTrip.gearLossStep.nbGear')"
                  :value="gearLoss['nbGear']"
                  type="text"
                />
              </b-col>
            </b-row>
            <b-row v-if="gearLoss['remark']">
              <b-col lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.remarkTitle')"
                  :value="gearLoss['remark']"
                  type="text"
                />
              </b-col>
            </b-row>
          </fieldset>
        </div>
        <BaitViewer
          v-if="tow.baitUsages && tow.baitUsages.length > 0"
          :baitUsages="tow.baitUsages"
        />
        <br />
      </fieldset>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import BaitViewer from "@/components/viewtrip/BaitViewer.vue";
import CatchViewer from "@/components/viewtrip/CatchViewer.vue";
import Geolocation from "@/utils/geolocation.js";
import LabelView from "@/components/LabelView.vue";
import TagsViewer from "@/components/viewtrip/TagsViewer.vue";
import InfoViewer from "@/components/viewtrip/InfoViewer.vue";

export default {
  name: "TowViewer",
  components: {
    LabelView,
    BaitViewer,
    CatchViewer,
    InfoViewer,
    TagsViewer
  },
  props: {
    tows: {
      type: Array,
      required: true
    },
    target: {
      type: Number
    },
    area: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapState({
      systemsLists: state => state.systemsLists,
      currentTrip: state => state.selectedTrip,

      // TODO: BL - La présence de userProfile afin de satisfaire une dépendance
      // dans $showdate représente un workaround. On devra un jour trouver une
      // meilleure solution.
      userProfile: state => state.userProfile
    }),
    ...mapGetters(["isOldModule", "getPropertyValue"]),
    areas() {
      return this.getPropertyValue(
        "areas",
        {
          subforms: this.currentTrip.subform,
          regions: this.currentTrip.dfoRegion,
          targetSpecies: this.target
        },
        []
      );
    },
    effortDetailDateTextMode() {
      return this.getPropertyValue("effortDetailDateTextMode", {
        forms: this.currentTrip.form
      });
    },
    isTowTextMode() {
      return this.effortDetailDateTextMode === 1;
    }
  },
  methods: {
    convertDD2toDMS(position, type) {
      return Geolocation.convertDD2toDMS(position, type).toString;
    }
  }
};
</script>

<style lang="scss" scoped>
table {
  margin-top: 10px;
  width: 100%;
}

td {
  padding: 10px;
  border: 1px solid #d2d2d4;
}

th {
  background-color: $mediumColor;
  padding: 10px;
}
.fieldsetDetailsNoBorder {
  border: false;
  margin-top: 15px;
  width: 95%;
  margin-left: 2.5% !important;
  margin-right: 2.5% !important;
}
.addTopMargin {
  margin-top: 2%;
}
</style>
