<template>
  <p :name="id" class="field-wrapper" :class="{ uneditable: uneditable }">
    <span class="label">
      <span>
        <label>
          {{ label }}
        </label>
      </span>
      <a v-if="hasHelp" :id="'tooltipButton' + id" class="dot"> ? </a>
      <b-tooltip
        v-if="hasHelp"
        :target="'tooltipButton' + id"
        :title="help"
        placement="topleft"
        variant="primary"
        triggers="click"
      >
      </b-tooltip>
    </span>
    <b-input
      type="number"
      number
      :class="errorClass"
      :disabled="disabled"
      :max="max"
      :min="min"
      :placeholder="placeholder"
      :required="required"
      :value="currentValue"
      @blur="focusChanged"
      @input="onChange($event)"
      @keydown="onKeydown($event)"
    />
    <label v-if="isError || forceErrorMsg" class="error">
      {{ errorMessage }}
    </label>
  </p>
</template>

<script>
import Validator from "@/utils/validator.js";

export default {
  name: "IntegerInput",
  props: {
    value: {
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    refeshError: {
      default: false
    },
    errorMsg: {
      type: String,
      default: ""
    },
    forceErrorMsg: {
      default: undefined
    },
    uneditable: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number,
      default: undefined
    },
    max: {
      type: Number,
      default: undefined
    },
    help: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      id: this.$options.name + "-" + (Math.random() * 100000).toFixed(0),
      currentValue: null,
      defaultErrorMsg: "",
      errorClass: { error: false },
      selectClass: [],
      hasHelp: !!this.help,
      isError: false
    };
  },
  computed: {
    valueLength() {
      return this.value ? this.value.length : null;
    },
    filter() {
      let filter = {
        type: "number",
        required: this.required,
        min: this.min,
        max: this.max
      };
      return filter;
    },
    errorMessage() {
      let msg = this.defaultErrorMsg;
      if (this.forceErrorMsg) {
        msg = this.forceErrorMsg;
      } else if (this.errorMsg) {
        msg = this.errorMsg;
      }

      return msg;
    }
  },
  watch: {
    refeshError() {
      this.refreshErrorMethod();
    },
    isError() {
      this.errorClass.error = this.isError || this.forceErrorMsg?.length > 0;
      let object = {};
      object[this.id] = this.errorClass.error;
      this.$emit("error", object);
    },
    value() {
      this.currentValue = this.value;
    }
  },
  methods: {
    refreshErrorMethod() {
      this.setError(this.value);
    },
    setError(value) {
      const rt = Validator.genericValidation(value, this.filter);
      this.isError = rt.error;
      this.defaultErrorMsg = rt.msg;
      this.errorClass.error = this.isError || this.forceErrorMsg?.length > 0;
      let object = {};
      object[this.id] = this.errorClass.error;

      if (rt.notBlocked) {
        return;
      }
      this.$emit("error", object);
    },
    setCharAt(str, index, chr) {
      if (str === undefined) {
        return str;
      }
      if (index > str.length - 1) return str;
      return str.substring(0, index) + chr + str.substring(index + 1);
    },
    focusChanged(e) {
      this.setError(this.value);
      this.$emit("binding", this.value);
    },
    onChange(value) {
      if (value === undefined || value === "") {
        value = null;
      } else value = value;
      if (value != null) this.$emit("input", parseInt(value));
      else this.$emit("input", value);
    },
    formatValue(value) {
      let x = value;
      x = x.trim();
      x = x.replace(/[^0-9]/g, "");
      return x;
    },
    onKeydown(e) {
      if (!this.isValidKey(e)) {
        e.preventDefault();
      }
    },
    isValidKey(e) {
      let key = Number(e.key);
      let keyName = e.key.toLowerCase();
      if (
        !(
          keyName == "arrowleft" ||
          keyName == "arrowright" ||
          keyName == "arrowup" ||
          keyName == "arrowdown" ||
          keyName == "backspace" ||
          keyName == "tab" ||
          keyName == "enter" ||
          keyName == "delete"
        ) &&
        (e.key === null || isNaN(key) || e.key === " ")
      ) {
        return false;
      } else {
        return true;
      }
    }
  },
  mounted() {
    if (this.value) {
      this.currentValue = this.value;
    }
  },
  created() {},
  beforeDestroy() {
    let object = {};
    object[this.id] = false;
    this.$emit("error", object);
    //document.removeEventListener('focusout', this.focusChanged)
  }
};
</script>

<style lang="scss" scoped>
.col-4 {
  padding: 0;
}

.label {
  color: #000;
}

.page form div > input:not([type="submit"]):not([type="button"]) {
  padding-right: 0.2rem;
  border-right-width: 0px;
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}
</style>
