<template>
  <div class="list">
    <h3 class="step-title">
      <span>
        <span>
          <span>{{ $t("editTrip.effortStep.title") }}</span>
        </span>
      </span>
    </h3>
    <div class="suppEntry" v-for="(effort, index) in form.efforts" :key="index">
      <h4 class="level1">
        {{ $t("editTrip.effortStep.effort") }}&nbsp;{{ index + 1 }}
      </h4>
      <b-overlay
        :show="effort['closeDatetime'] !== undefined"
        rounded="sm"
        z-index="100"
        class="closeOverley"
        blur="none"
        :key="'effort' + index + effort['closeDatetime']"
      >
        <template #overlay>
          <b-icon icon="file-lock" variant="danger" scale="2"></b-icon>
          <p class="redMessage">{{ $t("closeMsg") }}</p>
          <p class="redMessage">
            {{ $t("closeMsgDate") }}{{ showDate(effort["closeDatetime"]) }}
          </p>
        </template>

        <fieldset>
          <h4 class="level2">{{ $t("editTrip.effortStep.fishingInfos") }}</h4>
          <Select
            v-if="licencesOptions.length > 2"
            :label="$t('editTrip.effortStep.licenceNumber')"
            v-model="effort.licenceNumber"
            :options="licencesOptions"
            :required="true"
          />

          <FishingInfoDisplay
            :licence="selectedLicence(effort.licenceNumber)"
            :displayLicenceNumber="licencesOptions.length === 2"
            :effort="effort"
            :index="index"
            :addErrorToParent="
              error => {
                addErrors(error, index);
              }
            "
          />
        </fieldset>

        <BeginEffort
          :effort="effort"
          :addErrorToParent="
            error => {
              addErrors(error, index);
            }
          "
          :showErrorData="showErrorData + refreshIndex[index]"
          :form="form"
          :index="index"
        />
        <fieldset :disabled="effort['closeDatetime'] !== undefined">
          <FishingGears
            :effort="effort"
            :addErrorToParent="
              error => {
                addErrors(error, index);
              }
            "
            :showErrorData="showErrorData + refreshIndex[index]"
            :index="index"
          />
          <Tow
            :effort="effort"
            :addErrorToParent="
              error => {
                addErrors(error, index);
              }
            "
            :showErrorData="showErrorData + refreshIndex[index]"
            :form="form"
            :index="index"
          />

          <EndEffort
            :effort="effort"
            :addErrorToParent="
              error => {
                addErrors(error, index);
              }
            "
            :showErrorData="showErrorData + refreshIndex[index]"
            :form="form"
            :index="index"
          />
        </fieldset>

        <fieldset class="suppEntryButtonWrapper removeBottomPadding">
          <div>
            <input
              v-if="!effort['closeDatetime']"
              @click="closeEffort(index)"
              class="closeSuppEntryButton"
              :class="index > 0 ? 'removeBottomMargin' : null"
              type="button"
              :value="$t('editTrip.effortStep.closeEffort') + String(index + 1)"
            />
          </div>
        </fieldset>
      </b-overlay>
    </div>
    <div class="suppEntryButtonWrapper addBottomPadding">
      <div>
        <input
          v-if="
            form.efforts &&
              form.efforts.length > 0 &&
              !$isLastItemClosed(form.efforts)
          "
          @click="deleteEffort"
          class="removeSuppEntryButton removeTopMargin"
          type="button"
          :value="
            $t('editTrip.effortStep.deleteEffortButton') +
              String(form.efforts.length)
          "
        />
        <input
          @click="addEffort"
          class="addSuppEntryButton"
          type="button"
          :value="$t('editTrip.effortStep.addEffort')"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
import BeginEffort from "@/components/subformEditTrip/EffortStepComponents/BeginEffort.vue";
import Tow from "@/components/subformEditTrip/EffortStepComponents/Tow.vue";
import EndEffort from "@/components/subformEditTrip/EffortStepComponents/EndEffort.vue";
import FishingGears from "@/components/subformEditTrip/EffortStepComponents/FishingGears.vue";
import FishingInfoDisplay from "@/components/subformEditTrip/EffortStepComponents/FishingInfoDisplay.vue";
import Select from "@/components/Select.vue";
import DateFormat from "@/utils/dateFormat";
import { mapping } from "@/utils/FormStateMapping";
import {
  keysMatchingValue,
  scrollToTopField,
  createEffort
} from "@/utils/utils";

export default {
  components: {
    BeginEffort,
    Tow,
    FishingGears,
    EndEffort,
    FishingInfoDisplay,
    Select
  },
  name: "EffortsStep",
  data: () => ({
    form: {
      efforts: []
    },
    licencesOptions: [],
    showErrorData: 0,
    name: "effortsStep",
    refreshIndex: []
  }),
  computed: {
    ...mapState({
      showError: state => state.editTripSubform.showError,
      isFinalReview: state => state.editTripSubform.isFinalReview,
      currentPage: state => state.editTripSubform.currentPage,
      openTrip: state => state.currentOpenTrip,
      userProfile: state => state.userProfile,
      subscription: state => state.currentSubscription,
      stateErrors: state => state.editTripSubform.errors,
      GearTypes: state => state.editTripSubform[mapping.GearsTypes.stateName],
      licences: state =>
        Object.values(state.currentSubscription.preferences.licences)
    }),
    ...mapGetters(["isOldModule"]),
    selectedLicence() {
      return selectedLicence => {
        const licence = this.licences.find(
          x => x.fishingLicenceNumber === selectedLicence
        );
        return licence;
      };
    }
  },
  watch: {
    form: {
      deep: true,
      handler: function() {
        this.$emit("update-form", this.form);
      }
    },
    showError() {
      if (this.isFinalReview || this.currentPage === this.name) {
        ++this.showErrorData;
      }
    }
  },
  methods: {
    ...mapActions(["addErrorsToState", "closeObject"]),
    buildLicencesOptions() {
      const options = this.licences.map(licence => ({
        value: licence.fishingLicenceNumber,
        text: licence.fishingLicenceNumber
      }));
      this.licencesOptions = [
        { value: null, text: "---------", disabled: true },
        ...options
      ];
    },
    closeEffort(index) {
      Vue.set(this.refreshIndex, index, this.refreshIndex[index] + 1);
      this.closeObject({
        value: this.form.efforts,
        type: `${this.name}-${index}`,
        index: index,
        vm: this
      });
    },
    addErrors(errors, index) {
      this.addErrorsToState({ page: `${this.name}-${index}`, errors: errors });
      this.addErrorsToState({ page: this.name, errors: errors });
    },
    showDate(d) {
      const date = new Date(d);
      return date.toLocaleString(this.$i18n.locale, {
        timeZone: DateFormat.getZone(this.userProfile)
      });
    },
    async addEffort() {
      this.showErrorData += 1;
      await this.$nextTick();
      const errorsFieldsNames = keysMatchingValue(
        this.stateErrors,
        true,
        `${this.name}-${this.form.efforts.length - 1}`
      );
      if (errorsFieldsNames.length > 0) {
        scrollToTopField(errorsFieldsNames);
        return;
      }
      const initialEffort = this.defaultEffort();
      this.form.efforts.push(initialEffort);
      this.refreshIndex.push(0);
    },
    deleteEffort() {
      this.form.efforts.pop();
      this.refreshIndex.pop();
    },
    defaultEffort() {
      let preferences = this.subscription.preferences;

      let licence = Object.values(preferences.licences)[0];
      let effort = createEffort(preferences, licence);

      if (this.GearTypes.length === 2) {
        effort.gears = this.GearTypes[1].value;
      }

      return effort;
    }
  },
  beforeMount() {
    this.buildLicencesOptions();
  },
  mounted() {
    if (
      this.openTrip &&
      this.openTrip.efforts &&
      this.openTrip.efforts.length > 0
    ) {
      this.form.efforts = this.openTrip.efforts;
    }
    if (this.form.efforts.length === 0) {
      const initialEffort = this.defaultEffort();
      this.form.efforts.push(initialEffort);
    }
    this.refreshIndex = Array(this.form.efforts.length).fill(0);
  }
};
</script>
<style lang="scss" scoped></style>
